body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import 'https://js.arcgis.com/4.27/@arcgis/core/assets/esri/themes/dark/main.css';
html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
.ant-btn-danger{
  outline: 4px solid #331714;
  outline-offset: 1px;
  transition: outline-offset 0s,outline 0s;
  background: #a63a3a;
}
.react-grid-layout > .react-grid-item > .ant-card > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra > .ant-btn {
  margin: 0 0.2rem;
}